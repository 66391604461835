<template>
  <div>
    <VuePerfectScrollbar class="scroll" :settings="settings" ref="comment">
      <div v-if="loading" class="d-flex justify-content-center py-1">
        <BSpinner />
      </div>
      <div class="overflow-hidden p-50">
        <app-timeline>
          <template v-for="comment in GET_COMMENTS">
            <CommentsItem
              :key="comment.id"
              @deleteComment="deleteComment"
              @replyComment="data => (replyComment = data)"
              @updateComment="updateComment"
              :lazyPaymentBtn="lazyPaymentBtn"
              :comment="comment"
              line
            />
          </template>
        </app-timeline>
      </div>
    </VuePerfectScrollbar>

    <div>
      <div v-if="replyComment">
        <hr />
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex">
            <div class="pr-1 pt-25">
              <feather-icon icon="CornerUpLeftIcon" size="30" />
            </div>
            <div>
              <b-link href="#">{{ replyComment.author.phone }}</b-link>
              <p>{{ replyComment.message }}</p>
            </div>
          </div>

          <div>
            <feather-icon
              icon="XIcon"
              class="cursor-pointer"
              size="20"
              @click="replyComment = null"
            />
          </div>
        </div>
      </div>

      <b-form-textarea
        v-model="inputValue"
        :placeholder="$t('task_manager.write_comment')"
      >
      </b-form-textarea>

      <div class="d-flex justify-content-end pt-50">
        <b-button
          size="sm"
          variant="primary"
          :disabled="!inputValue.trim()"
          @click="addComment"
        >
          {{ $t('task_manager.leave_comment') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BLink,
  BButton,
  BListGroup,
  BAvatarGroup,
  BBadge,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { getUserData } from '@/auth/utils'
import CommentsItem from './CommentsItem.vue'

export default {
  props: {
    object_id: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      inputValue: '',
      lazyPaymentBtn: false,
      replyComment: null,
      settings: {
        maxScrollbarLength: 40,
      },
      _content_type: null,
    }
  },
  methods: {
    ...mapActions('taskManager', [
      'POST_COMMENT',
      'FETCH_COMMENTS',
      'DELETE_COMMENT',
      'FETCH_TIME_LINE_HISTORY',
      'UPDATE_COMMENT',
      'FETCH_ONE_ACCOUNT',
      'FETCH_PAYMENT_HISTORY',
    ]),

    async fetchComment() {
      this.loading = true
      await this.FETCH_COMMENTS({
        offset: this.GET_COMMENTS.length,
        object_id: this.object_id,
        content_type: this.GET_ONE_ACCOUNT.content_type,
      })

      this.loading = false
    },
    async addComment() {
      if (!this.inputValue.trim()) {
        return (this.inputValue = '')
      }
      try {
        const message = {
          object_id: this.$route.params.id,
          author: getUserData().id,
          message: this.inputValue,
          content_type: this.GET_ONE_ACCOUNT.content_type,
        }
        if (this.replyComment) {
          message.parent = this.replyComment.id
        }
        await this.POST_COMMENT(message)
        this.fetchComment()

        this.inputValue = ''

        this.replyComment = null

        this.$_successToast(this.$t('general.success'))
      } catch (err) {
        this.$_errorToast(this.$t('general.error'))
      }
    },
    async deleteComment(comment) {
      try {
        await this.DELETE_COMMENT(comment.id)

        this.$_successToast(this.$t('general.success'))

        if (this.replyComment && this.replyComment.id == comment.id) {
          this.replyComment = null
        }

        this.fetchComment()
      } catch (err) {
        this.$_errorToast(this.$t('general.error'))
      }
    },

    async updateComment(comment) {
      this.lazyPaymentBtn = true

      try {
        await this.UPDATE_COMMENT({
          id: comment.id,
          data: {
            object_id: this.object_id,
            content_type: this.GET_ONE_ACCOUNT.content_type,
            is_waiting_payment: false,
            partial_amount: comment.partial_amount,
          },
        })
        await this.FETCH_COMMENTS({
          object_id: this.object_id,
          content_type: this.GET_ONE_ACCOUNT.content_type,
        })

        await this.FETCH_ONE_ACCOUNT(this.$route.params.id)

        const lastStepId = this.GET_TIME_LINE_HISTORY[
          this.GET_TIME_LINE_HISTORY.length - 1
        ].id

        await this.FETCH_PAYMENT_HISTORY({
          account: this.$route.params.id,
          step: lastStepId,
        })

        this.FETCH_TIME_LINE_HISTORY({
          account: this.$route.params.id,
        })
      } catch (error) {
        console.log('error', error)
        this.$_errorToast(this.$t('general.error'))
      }

      this.lazyPaymentBtn = false
    },
  },

  async mounted() {
    try {
      this.loading = true

      await this.FETCH_COMMENTS({
        object_id: this.object_id,
        content_type: this.GET_ONE_ACCOUNT.content_type,
      }).then(() => {
        this.loading = false
      })
    } catch (err) {
      this.$_errorToast()
    }
  },
  computed: {
    ...mapGetters('taskManager', [
      'GET_COMMENTS',
      'GET_ONE_ACCOUNT',
      'GET_TIME_LINE_HISTORY',
    ]),
  },
  components: {
    AppTimeline,
    VuePerfectScrollbar,
    CommentsItem,
    BLink,
    BAvatar,
    BButton,
    BListGroup,
    BAvatarGroup,
    BBadge,
    BFormTextarea,
    BSpinner,
  },
}
</script>

<style scoped>
.scroll {
  max-height: 85vh;
}

/* .comment {
  min-height: 25px;
  border-bottom: 2px solid lightgrey;
  outline: none !important;
} */

.scroll {
  height: 50vh;
}

.comment {
  max-width: 90%;
}
</style>
