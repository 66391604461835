<template>
  <div>
    <b-list-group>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5 class="mr-2">
          {{ $t('task_manager.client_name') }}
        </h5>
        <div>
          {{ GET_ONE_ACCOUNT.contractor.title }}
          <b-button
            v-if="isShowBtn"
            variant="outline-warning"
            class="btn-sm"
            @click="handleEditAccount"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5>
          ИНН:
        </h5>
        <div>
          {{ GET_ONE_ACCOUNT.contractor.inn }}
        </div>
      </b-list-group-item>

      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5>
          Плательщик
        </h5>
        <div>
          {{ GET_ONE_ACCOUNT.payer.title }}
        </div>
      </b-list-group-item>
       <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5>
          Расчетный счет контрагента
        </h5>
        <div>
          {{ GET_ONE_ACCOUNT.settlement_account.settlement_account }}
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5>
          Город
        </h5>
        <div>
          {{ GET_ONE_ACCOUNT.city.title }}
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5>
          Договор
        </h5>
        <div>
          {{ GET_ONE_ACCOUNT.contract.title }}
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5>
          {{ $t('status') }}
        </h5>
        <b-badge :variant="GET_ONE_ACCOUNT.current_step.color">
          {{ GET_ONE_ACCOUNT.current_step.title[$i18n.locale] }}
        </b-badge>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5>
          {{ $t('finance.summ') }}
        </h5>
        <div>
          {{ $_moneyFormatter(GET_ONE_ACCOUNT.amount) }}
          <!-- {{ GET_ONE_ACCOUNT.modified_date }} -->
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5>
          {{ $t('task_manager.created_by') }}
        </h5>
        <div>
          {{ getDate(GET_ONE_ACCOUNT.created_date) }}
          {{ getHours(GET_ONE_ACCOUNT.created_date) }}
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5>
          {{ $t('task_manager.modified') }}
        </h5>
        <div>
          {{ getDate(GET_ONE_ACCOUNT.modified_date) }}
          {{ getHours(GET_ONE_ACCOUNT.modified_date) }}
          <!-- {{ GET_ONE_ACCOUNT.modified_date }} -->
        </div>
      </b-list-group-item>
    </b-list-group>

    <b-list-group class="mt-2">
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5>
          <feather-icon icon="UserIcon" class="mr-50" size="16" />{{
            $t('general.author')
          }}
        </h5>
        <div>
          {{ GET_ONE_ACCOUNT.author.full_name }}
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5><feather-icon icon="MailIcon" class="mr-50" size="16" />Email</h5>
        <div>{{ GET_ONE_ACCOUNT.author.email }}</div>
      </b-list-group-item>

      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5>
          <feather-icon icon="GitlabIcon" class="mr-50" size="16" />{{
            $t('user.role')
          }}
        </h5>
        <div v-for="(role, index) in GET_ONE_ACCOUNT.author.role" :key="index">
          <b-badge variant="success">
            {{ role.name[$i18n.locale] }}
          </b-badge>
        </div>
      </b-list-group-item>
    </b-list-group>
<!-- 
    <app-collapse accordion class="mt-2" v-if="GET_ONE_ACCOUNT.description">
      <app-collapse-item title="Описание">
        {{ GET_ONE_ACCOUNT.description }}

      </app-collapse-item>
    </app-collapse> -->

    <AddTaskModal :editItem="editItem" @refresh="refresh" />
  </div>
</template>

<script>
import { BListGroup, BListGroupItem, BBadge, BButton } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import AddTaskModal from '../../components/AddTaskModal.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    AddTaskModal,
    AppCollapse,
    AppCollapseItem,
  },

  computed: {
    ...mapGetters('taskManager', ['GET_ONE_ACCOUNT']),

    isShowBtn() {
      if (
        this.GET_ONE_ACCOUNT.current_step.color == 'warning' ||
        this.GET_ONE_ACCOUNT.current_step.color == 'danger'
      ) {
        return true
      } else return false
    },
  },

  data() {
    return {
      editItem: {},
    }
  },
  methods: {
    ...mapActions('taskManager', ['FETCH_ONE_ACCOUNT']),

    refresh() {
      this.FETCH_ONE_ACCOUNT(this.$route.params.id)
    },
    getDate(date) {
      if (date) return moment(date).format('DD.MM.YYYY')
    },
    getHours(date) {
      if (date) return moment(date).format('HH:mm')
    },

    handleEditAccount() {
      this.editItem = this.GET_ONE_ACCOUNT
      this.$nextTick(() => {
        this.$bvModal.show('add-task-modal')
      })
    },
  },
}
</script>

<style></style>
