<template>
  <b-card>
    <b-row>
      <b-col
        md="12"
        class="mb-1 d-flex justify-content-between align-items-center flex-wrap"
      >
        <div>#{{ $route.params.id }} - {{ desc }}</div>
        <!-- <div>
          {{ $_moneyFormatter(GET_ONE_ACCOUNT.partial_amount) }}
        </div> -->
        <div class="d-flex align-items-center">
          <b-avatar
            v-if="GET_ONE_ACCOUNT.contacts"
            :src="GET_ONE_ACCOUNT.contacts.photo || ''"
            :title="GET_ONE_ACCOUNT.contacts.title"
            v-b-tooltip.hover
            class="pull-up"
            variant="primary"
            size="lg"
          />
          <div class="ml-1">
            <div class="font-weight-bold">
              <div>
                <small class="text-muted">
                  {{
                    GET_ONE_ACCOUNT.task_type
                      ? filterDataLang(GET_ONE_ACCOUNT, 'task_type')
                      : ''
                  }}
                </small>
              </div>

              {{
                GET_ONE_ACCOUNT.doc_type
                  ? filterDataLang(GET_ONE_ACCOUNT.doc_type)
                  : ''
              }}
            </div>
            <div class="font-small-2 text-muted"></div>
          </div>
        </div>

        <!-- {{ GET_STEPS }} -->
        <b-button
          v-if="isSeePlusButton"
          variant="primary"
          @click="openPaymentModal"
        >
          +</b-button
        >

        <div v-else class="d-flex justify-content-end">
          <b-dropdown
            v-if="is_change"
            right
            :disabled="lazyDrop"
            :text="filterDataLang(GET_ONE_ACCOUNT.current_step)"
            :variant="
              (GET_ONE_ACCOUNT.current_step &&
                GET_ONE_ACCOUNT.current_step.color) ||
                'primary'
            "
          >
            <template v-for="step in this.GET_STEPS">
              <b-dropdown-item
                :key="step.id"
                @click="changeStep(step.id)"
                v-if="
                  step.title[$i18n.locale] !=
                    filterDataLang(GET_ONE_ACCOUNT.current_step)
                "
              >
                {{ step.title[$i18n.locale] }}
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
      </b-col>

      <b-col md="12">
        <hr />
      </b-col>

      <!-- <b-col md="12">
        <div class="description mt-2">
          <p>{{ GET_ONE_ACCOUNT.description }}</p>
        </div>
      </b-col> -->
    </b-row>

    <Comments :object_id="$route.params.id" />
    <AddPaymentModal />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BButton,
  BCol,
  BAvatar,
  BDropdown,
  BSpinner,
  BDropdownItem,
} from 'bootstrap-vue'
import Comments from '../components/Comments.vue'
import { mapActions, mapGetters } from 'vuex'
import AddPaymentModal from '../components/AddPayment.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BSpinner,
    AddPaymentModal,
    BButton,
    Comments,
  },

  data() {
    return {
      lazyDrop: false,
    }
  },

  props: {
    desc: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('taskManager', ['GET_ONE_ACCOUNT', 'GET_STEPS']),

    isSeePlusButton() {
      if (
        this.GET_ONE_ACCOUNT.current_step.color == 'secondary' &&
        useJwt.getUserData()?.permission.includes('supervisors')
      )
        return true
      else return false
    },

    is_change() {
      if (this.GET_STEPS.length > 0) {
        return true
      } else {
        return false
      }
    },
  },
  async mounted() {
    await this.FETCH_STEPS({ account_id: this.$route.params.id })
  },

  methods: {
    ...mapActions('taskManager', [
      'FETCH_STEPS',
      'UPDATE_ACCOUNT',
      'FETCH_ONE_ACCOUNT',
      'FETCH_TIME_LINE_HISTORY',
    ]),

    openPaymentModal() {
      this.$bvModal.show('add-payment-modal')
    },

    async changeStep(id) {
      try {
        this.lazyDrop = true

        const task = {
          id: this.GET_ONE_ACCOUNT.id,
          data: { current_step: id },
        }
        await this.UPDATE_ACCOUNT(task)
        await this.FETCH_ONE_ACCOUNT(this.$route.params.id)
        await this.FETCH_STEPS({ account_id: this.$route.params.id })
        this.FETCH_TIME_LINE_HISTORY({
          account: this.$route.params.id,
        })

        this.$_successToast(this.$t('general.success'))
      } catch (error) {
        console.log('error', error)
        this.$_errorToast(this.$t('general.error'))
      }

      this.lazyDrop = false
    },

    filterDataLang(v = {}, key = 'title') {
      let value = v[key] || {} // убрал проверку на null или undefined
      let locale = this.$i18n.locale
      let def = {
        title: {
          ru: '',
          uz: '',
        },
        level: {
          ru: 'Низкий',
          uz: 'Низкий',
        },
      }
      return value[locale] ? value[locale] : value.ru || def[key][locale]
    },
  },
}
</script>

<style></style>
