<template>
  <b-card v-if="GET_TIME_LINE_HISTORY.length">
    <app-timeline>
      <app-timeline-item
        v-for="(history, index) in GET_TIME_LINE_HISTORY"
        :key="index"
        :title="history.step_to.title[$i18n.locale]"
        :user="history.author"
        :time="history.created_at"
        :variant="history.step_to.color || 'success'"
        icon=""
      />
      <!-- :payment_history = "GET_PAYMENT_HISTORY.results" -->
    </app-timeline>

    <div>
      <hr />
      <div
        v-for="payment in GET_PAYMENT_HISTORY.results"
        :key="payment.id"
        class="d-flex justify-content-between align-items-center mb-50"
      >
        <b-badge variant="light-success">
          {{ $_moneyFormatter(payment.summa) }}
        </b-badge>
        <div>
          <div>{{ payment.created_at }}</div>
          <small
            class="timeline-item-time text-nowrap text-muted"
            v-text="payment.author.full_name"
          />
        </div>
      </div>
    </div>
    <!-- 
    <pre>
       {{ GET_PAYMENT_HISTORY }}
 </pre
    > -->
  </b-card>
</template>

<script>
import { BCard, BBadge } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BBadge,
    AppTimeline,
    AppTimelineItem,
  },

  computed: {
    ...mapGetters('taskManager', [
      'GET_ONE_ACCOUNT',
      'GET_TIME_LINE_HISTORY',

      'GET_PAYMENT_HISTORY',
    ]),
  },
  async mounted() {
    await this.FETCH_TIME_LINE_HISTORY({
      account: this.$route.params.id,
    })

    // this.FETCH_PAYMENT_HISTORY()
    let lastStepId = this.GET_TIME_LINE_HISTORY.find(
      item => item.step_to.color == 'secondary',
    )

    if (!lastStepId?.id) {
      lastStepId = this.GET_TIME_LINE_HISTORY.slice(-1).pop()
    }

    console.log('lastStepid', lastStepId)

    await this.FETCH_PAYMENT_HISTORY({
      account: this.$route.params.id,
      step: lastStepId.id,
    })
  },

  methods: {
    ...mapActions('taskManager', [
      'FETCH_TIME_LINE_HISTORY',
      'FETCH_PAYMENT_HISTORY',
    ]),

    formdatDate(v) {
      return moment(v).format('HH:MM, DD.MM.YYYY')
    },
  },
}
</script>

<style></style>
