<template>
  <div>
    <b-row>
      <b-col md="3">
        <ContactInfo />
      </b-col>

      <b-col md="5">
        <CommentsSection
          :desc="GET_ONE_ACCOUNT.description"
    
        />
      </b-col>

      <b-col md="4">
        <HistoryTimeLine />
        <FileAttachments />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ContactInfo from './layout/ContactInfo.vue'
import CommentsSection from './layout/CommentsSection.vue'
import HistoryTimeLine from './layout/HistoryTimeLine.vue'
import FileAttachments from './layout/FileAttachments.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    ContactInfo,
    CommentsSection,
    HistoryTimeLine,
    FileAttachments,
  },

  computed: {
    ...mapGetters('taskManager', ['GET_ONE_USER', 'GET_ONE_ACCOUNT']),
  },

  methods: {
    ...mapActions('taskManager', ['FETCH_ONE_ACCOUNT']),
 
  },

  async beforeMount() {
    await this.FETCH_ONE_ACCOUNT(this.$route.params.id)
  },
}
</script>
