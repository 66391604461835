<template>
  <app-timeline-item variant="success" :author="true">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <b-avatar
          v-if="comment.author"
          size="40px"
          variant="primary"
          class="pull-up"
          :title="comment.author ? comment.author.name : ''"
          :src="comment.author.photo"
        />
        <b-avatar v-else v-b-tooltip.hover class="pull-up" title="222" />
        <b-link class="ml-1" href="#">{{
          comment.author.full_name || 'Нет данных'
        }}</b-link>

        <small class="text-muted ml-1">{{
          dateFormat(comment.created_date)
        }}</small>
      </div>
      <!-- {{ comment.is_transaction }} -->
      <!-- {{ comment }} -->
      <div v-if="is_show_btn(comment)">
        <BButton
          variant="primary"
          size="sm"
          @click="$emit('updateComment', comment)"
          :disabled="lazyPaymentBtn"
        >
          Оплатить
        </BButton>
      </div>
    </div>
    <div>
      <!-- <h6>
        <b-link href="#">{{ comment.author.phone || 'Нет данных' }}</b-link>
        <small class="text-muted ml-1">{{
          dateFormat(comment.created_date)
        }}</small>
      </h6> -->
    </div>

    <p class="comment">{{ comment.message }}</p>
    <div>
      <span
        v-if="showDeleteBtn(comment)"
        class="text-muted mr-1 cursor-pointer"
        @click="$emit('deleteComment', comment)"
        >{{ $t('general.delete') }}</span
      >
      <span
        class="text-muted cursor-pointer"
        v-if="reply"
        @click="$emit('replyComment', comment)"
      >
        {{ $t('general.answer') }}
      </span>
      <BBadge
        v-if="comment.is_transaction"
        class="ml-1"
        :variant="
          comment.is_waiting_payment ? 'light-warning' : 'light-success'
        "
        pill
      >
        {{ comment.is_waiting_payment ? 'Ожидание оплаты' : 'Оплачено' }}
      </BBadge>
    </div>

    <div class="pt-50" v-if="comment.children">
      <small v-if="comment.children.length">
        <b-link @click="collapse = !collapse">
          <feather-icon
            :icon="collapse ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          />
          {{ $t('general.show') }} {{ comment.children.length }}
          {{ $t('general.answers') }}
        </b-link>
      </small>

      <app-timeline v-if="collapse" class="mt-1">
        <CommentsItem
          v-for="commentChild in comment.children"
          :key="commentChild.id"
          :reply="false"
          :comment="commentChild"
          @deleteComment="$emit('deleteComment', commentChild)"
        />
      </app-timeline>
    </div>

    <hr v-if="line" />
  </app-timeline-item>
</template>

<script>
import { BLink, BAvatar, BButton, BBadge } from 'bootstrap-vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CommentsItem',
  props: {
    comment: {
      type: Object,
      required: true,
    },
    reply: {
      type: Boolean,
      required: false,
      default: true,
    },
    line: {
      type: Boolean,
      required: false,
      default: false,
    },

    lazyPaymentBtn: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      collapse: false,
    }
  },
  methods: {
    ...mapActions('taskManager', ['UPDATE_COMMENT']),

    dateFormat(date) {
      moment.locale(this.currentLanguage)
      return moment(date).calendar()
      // console.log('moment',date, moment(date).fromNow())
      // return moment(date, 'YYYYMMDD').fromNow()
    },

    is_show_btn(item) {
      if (
        useJwt.getUserData().permission.includes('accountants') &&
        item.is_transaction &&
        item.is_waiting_payment
      )
        return true
      else return false
    },

    showDeleteBtn(comment) {
      if (comment.is_transaction && !comment.is_waiting_payment) {
        return false
      }
      if (comment.is_transaction && comment.is_waiting_payment) {
        return true
      }
      if (!comment.is_transaction && !comment.is_waiting_payment) {
        return true
      }
    },
  },

  computed: {
    currentLanguage() {
      return this.$i18n.locale === 'uz' ? 'uz-latn' : 'ru'
    },
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    BAvatar,
    BLink,
    BButton,
    BBadge,
  },
}
</script>

<style scoped>
.comment {
  max-width: 90%;
}
</style>
