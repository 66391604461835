<template>
  <BModal
    id="add-payment-modal"
    ok-variant="success"
    centered
    no-close-on-backdrop
    cancel-variant="danger"
    :ok-title="$t('save')"
    :cancel-title="$t('client.cancel')"
    @ok="onSubmit"
    @show="openModal"
  >
    <ValidationObserver ref="AddPaymentModal">
      <BFormGroup
        :label="$t('finance.summ') + ' :'"
        label-for="contacts-author"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('finance.summ')"
          rules="required"
        >
          <cleave
            v-model="partial_amount"
            :options="moneyOptions"
            class="form-control"
            :placeholder="$t('finance.summ')"
            tabindex="5"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </BFormGroup>
    </ValidationObserver>
  </BModal>
</template>



<script>
import { BModal, BFormInput, BFormGroup } from 'bootstrap-vue'
import { required, integer, length } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,

    Cleave,
  },

  data() {
    return {
      integer,
      length,
      inn: '',
      partial_amount: 0,
      moneyOptions: {
        numeral: true,
        // prefix: ' UZS',
        rawValueTrimPrefix: true,
        numeralPositiveOnly: true,
        tailPrefix: true,
        noImmediatePrefix: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    }
  },
  mounted() {},
  computed: {
    ...mapGetters('taskManager', [
      'GET_COMMENTS',
      'GET_ONE_ACCOUNT',
      'GET_TIME_LINE_HISTORY',
    ]),
  },
  methods: {
    ...mapActions('taskManager', [
      'UPDATE_ACCOUNT',
      'FETCH_ONE_ACCOUNT',
      'FETCH_COMMENTS',
      'FETCH_TIME_LINE_HISTORY',
      'FETCH_PAYMENT_HISTORY',
    ]),

    openModal() {
      this.partial_amount = 0
    },

    async onSubmit(evt) {
      evt.preventDefault()

      const res = await this.$refs.AddPaymentModal.validate()
      if (!res) {
        return
      }

      const data = {
        author: useJwt.getUserData()?.id,
        partial_amount: this.partial_amount,
      }

      try {
        await this.UPDATE_ACCOUNT({
          data: data,
          id: this.$route.params.id,
        })

        // this.$emit('refresh')
        await this.FETCH_ONE_ACCOUNT(this.$route.params.id)
        await this.FETCH_COMMENTS({
          offset: this.GET_COMMENTS.length,
          object_id: this.$route.params.id,
        })

        const lastStepId = this.GET_TIME_LINE_HISTORY[
          this.GET_TIME_LINE_HISTORY.length - 1
        ].id

        await this.FETCH_PAYMENT_HISTORY({
          account: this.$route.params.id,
          step: lastStepId,
        })

        this.FETCH_TIME_LINE_HISTORY({
          account: this.$route.params.id,
        })

        this.$nextTick(() => {
          this.$_successToast(this.$t('client.successfully_added'))
          this.$bvModal.hide('add-payment-modal')
        })
      } catch (error) {
        console.log(error.response.status)
        if (error.response.status == 406)
          this.$_errorToast(error.response.data.detail)
        else this.$_errorToast(this.$t('general.error'))
      }
    },
  },
}
</script>


