<template>
  <div>
    <b-card title="Акт ">
      <div v-if="isExistActFile">
        <BFormFile
          :browse-text="$t('upload')"
          :placeholder="$t('choose_file_or_drop')"
          :drop-placeholder="$t('choose_file_or_drop')"
          id="act_doc"
          v-model="act_doc"
        />
        <div class="d-flex justify-content-end pt-1">
          <b-button
            size="sm"
            variant="success"
            @click="uploadActFile"
            :disabled="!this.act_doc"
          >
            {{ $t('upload') }}
          </b-button>
        </div>
      </div>
      <div v-else class="d-flex align-items-center justify-content-between">
        <p class="file-text mr-2 mb-0 file-name">
          {{ GET_ONE_ACCOUNT.act_doc.title || 'No name' }}
        </p>
        <div class="d-flex">
          <b-button
            variant="success"
            size="sm"
            @click="checkFileType(GET_ONE_ACCOUNT.act_doc)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>

          <b-button
            class="mx-50"
            variant="primary"
            size="sm"
            @click="downloadFile(GET_ONE_ACCOUNT.act_doc.file)"
          >
            <feather-icon icon="DownloadIcon" />
          </b-button>

          <b-button variant="danger" size="sm" @click="deleteActFile">
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </div>
    </b-card>

    <b-card :title="$t('client.files')">
      <div>
        <BFormFile
          :browse-text="$t('upload')"
          :placeholder="$t('choose_file_or_drop')"
          :drop-placeholder="$t('choose_file_or_drop')"
          id="file"
          v-model="file"
        />
        <div class="d-flex justify-content-end pt-1">
          <b-button
            size="sm"
            variant="success"
            @click="uploadFile"
            :disabled="!this.file"
          >
            {{ $t('upload') }}
          </b-button>
        </div>
      </div>
    </b-card>

    <div>
      <div
        v-if="GET_ATTACHMENTS && GET_ATTACHMENTS.results.length"
        class="
              d-flex
              align-items-center
              justify-content-between
              px-1
              text-center
            "
      >
        <p>{{ $t('objects.title') }}</p>
        <p>{{ $t('actions') }}</p>
      </div>

      <b-card
        no-body
        class="p-1 b__card"
        v-for="file in GET_ATTACHMENTS.results"
        :key="file.id"
      >
        <div class="d-flex align-items-center justify-content-between">
          <p class="file-text mr-2 mb-0 file-name">
            {{ getFileNameFromUrl(file.file) }}
          </p>
          <div class="d-flex">
            <b-button variant="success" size="sm" @click="checkFileType(file)">
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              class="mx-50"
              variant="primary"
              size="sm"
              @click="downloadFile(file.file)"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>

            <b-button
              v-if="GET_ATTACHMENTS.results.length > 1"
              variant="danger"
              size="sm"
              @click="deleteFile(file.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </div>
      </b-card>
    </div>

    <SeeDocModal :document="document" />
  </div>
</template>

<script>
import { BCard, BButton, BFormFile } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { getUserData } from '@/auth/utils'
import SeeDocModal from '@/components/SeeDocModal.vue'

export default {
  components: {
    BCard,
    BFormFile,
    BButton,
    SeeDocModal,
  },

  data() {
    return {
      file: null,
      document: {},
      act_doc: null,
    }
  },

  computed: {
    ...mapGetters('taskManager', ['GET_ATTACHMENTS', 'GET_ONE_ACCOUNT']),
    isExistActFile() {
      if (this.GET_ONE_ACCOUNT.act_doc?.title) return false
      else return true
    },
  },
  mounted() {
    this.FETCH_ATTACHMENT_FILES({
      content_type: this.GET_ONE_ACCOUNT.content_type,
      object_id: this.$route.params.id,
    })
  },

  methods: {
    ...mapActions('taskManager', [
      'FETCH_ATTACHMENT_FILES',
      'UPLOAD_ATTACHMENT_FILE',
      'DELETE_ATTACHMENT_FILE',
      'UPDATE_ACCOUNT',
      'UPDATE_ACCOUNT_ACT_DOC',
      'UPDATE_ACCOUNT_UPDATE_DOC',
      'FETCH_STEPS',
      'FETCH_ONE_ACCOUNT',
      'FETCH_TIME_LINE_HISTORY',
    ]),
    downloadFile(url) {
      fetch(url).then(function(t) {
        return t.blob().then(b => {
          var a = document.createElement('a')
          a.href = URL.createObjectURL(b)
          a.setAttribute('download', 'документ')
          a.click()
        })
      })
    },

    deleteActFile() {
      this.$_showAreYouSureModal().then(response => {
        if (response) {
          this.UPDATE_ACCOUNT_ACT_DOC({
            id: this.$route.params.id,
            data: {
              account_id: this.$route.params.id,
              act_doc: null,
            },
          }).then(() => {
            this.FETCH_ONE_ACCOUNT(this.$route.params.id)
            this.FETCH_STEPS({ account_id: this.$route.params.id })
            this.FETCH_TIME_LINE_HISTORY({
              account: this.$route.params.id,
            })
          })
        }
      })
    },

    deleteMainFile() {
      this.$_showAreYouSureModal().then(response => {
        if (response) {
          this.UPDATE_ACCOUNT({
            id: this.$route.params.id,
            data: {
              file: null,
            },
          }).then(() => {
            this.FETCH_ONE_ACCOUNT(this.$route.params.id)
          })
        }
      })
    },

    deleteFile(id) {
      this.$_showAreYouSureModal().then(response => {
        if (response) {
          this.DELETE_ATTACHMENT_FILE(id)
            .then(() => {
              this.$_successToast(this.$t('general.successfully_removed'))
              this.FETCH_ATTACHMENT_FILES({
                content_type: this.GET_ONE_ACCOUNT.content_type,
                object_id: this.$route.params.id,
              })
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },

    getFileNameFromUrl(file) {
      let fileName = file.split('/')
      fileName = fileName[fileName.length - 1]
      return fileName
    },

    checkFileType(item) {
      this.document = item

      this.$nextTick(() => {
        this.$bvModal.show('see-doc-modal')
      })
    },

    async uploadActFile() {
      if (!this.act_doc) {
        return
      }
      try {
        const formData = new FormData()
        formData.append('act_doc', this.act_doc)

        await this.UPDATE_ACCOUNT_UPDATE_DOC({
          id: this.$route.params.id,
          data: formData,
        })
        await this.FETCH_ONE_ACCOUNT(this.$route.params.id)
        await this.FETCH_STEPS({ account_id: this.$route.params.id })
        await this.FETCH_TIME_LINE_HISTORY({
          account: this.$route.params.id,
        })
        this.act_doc = null
        this.$_successToast(this.$t('general.success'))
      } catch (err) {
        console.log(err)
        this.$_errorToast(err.response?.data?.error)
      }
    },

    async uploadFile() {
      if (!this.file) {
        return
      }
      try {
        const formData = new FormData()
        let userId = getUserData().id
        formData.append('file', this.file)
        formData.append('content_type', this.GET_ONE_ACCOUNT.content_type)
        formData.append('object_id', this.$route.params.id)
        formData.append('author', userId)

        await this.UPLOAD_ATTACHMENT_FILE(formData)
        this.FETCH_ATTACHMENT_FILES({
          content_type: this.GET_ONE_ACCOUNT.content_type,
          object_id: this.$route.params.id,
        })

        this.file = null
        this.$_successToast(this.$t('general.success'))
      } catch (err) {
        console.log(err)
        this.$_errorToast(this.$t('general.error'))
      }
    },
  },
}
</script>

<style scoped>
.file-name {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>
